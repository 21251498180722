// # 全般
// ========== ========== ========== ========== ==========
//
// サイトで最も明るい色
$lightest-color: #fff;
// サイトで最も暗い色
$darkest-color: #000;
// メインカラー
$primary-color: #000;
// $primary-color-lighter: color($primary-color lightness(+30%));
// $primary-color-darker: color($primary-color lightness(-30%));
// サブカラー
$secondary-color: #fc9003;
$secondary-color: #fc9003;
// $secondary-color-lighter: color($secondary-color lightness(30%));
// $secondary-color-darker: color($secondary-color lightness(30%));


// アクセントカラー
$accent-color: #a7161d;
// グレイカラー
$gray-color: #848484;
$gray-color02: #eee;
$gray-color03: #a6a6a6;
$gray-color04: #acacac;
$gray-color05: #b5b5b5;
$gray-color06: #7b7b7a;
$gray-color07: #666666;
$gray-color08: #cccccc;

//マーカーカラー
$marker-color: #fd9398;

// 警告カラー
$warning-color: #a7161d;
// 特殊カラー
$lineup-color-cube: #bcc0c3;

// テキストリンクカラー
$text-link-color: #daab39;

//グラデ背景
// $bg-color-grad01: linear-gradient(135deg, rgba(179, 172, 145, 1) 0%, rgba(179, 172, 145, 1) 50%, rgba(190, 184, 161, 1) 50%, rgba(190, 184, 161, 1) 50%, rgba(190, 184, 161, 1) 100%);
// $bg-color-grad02: linear-gradient(135deg, rgb(76, 100, 211, 0.5), rgba(207, 46, 146, 0.5), rgba(242, 105, 57, 0.5), rgba(255, 221, 131, 0.5));

// # タイポグラフィ
// ========== ========== ========== ========== ==========
//
// 標準のテキストの色
// $font-primary-color: $darkest-color;
// 選択時の背景色（ブラウザデフォルト: #B3D4FF）
// $selection-background-color: $primary-color-lighter;

// # ボックスモデル
// ========== ========== ========== ========== ==========
//
// 標準の境界線の色
$border-primary-color: #d2cdcd;

// # フォーム
// ========== ========== ========== ========== ==========
//
// フォーム要素フォーカス時のグローカラー
$btn-glow-color: #0570c7;
