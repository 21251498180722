// # タイポグラフィ
//
$root-font-size: 62.5%; // 10(base font size) / 16(Browser Default font size) = 0.625em * 100 = 62.5%
$base-font-size: 1.6rem; // 16pt base
$base-line-height: 1.7;
$indent-sub-list: .75em;

// # ヘッダー
//
$header-height: 50px;
$scroll-transition: 200ms ease-in-out;

// # 寸法
//
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1250px;
$page-viewport-width: 1100px;
$page-viewport-width-02: 1000px;
$page-base-width: 900px;
$page-viewport-side-margin: 30px;
$page-viewport-side-margin-xs: 15px;

@custom-media --xs /* xsのみ */ (max-width: $breakpoint-xs);
@custom-media --sm /* smのみ */ (min-width: $breakpoint-xs) and (max-width: resolve($breakpoint-sm - 1px));
@custom-media --md /* mdのみ */ (min-width: $breakpoint-sm) and (max-width: resolve($breakpoint-md - 1px));
@custom-media --lg /* lgのみ */ (min-width: $breakpoint-md) and (max-width: resolve($breakpoint-lg - 1px));
@custom-media --xl /* xlのみ */ (min-width: $breakpoint-lg);
@custom-media --xs-lte /* xs以下 = xsのみ */ (max-width: resolve($breakpoint-xs - 1px));
@custom-media --sm-lte /* sm以下 */ (max-width: resolve($breakpoint-sm - 1px));
@custom-media --md-lte /* md以下 */ (max-width: resolve($breakpoint-md - 1px));
@custom-media --lg-lte /* lg以下 */ (max-width: resolve($breakpoint-lg - 1px));
@custom-media --xs-gt /* xs超え */ (min-width: $breakpoint-xs);
@custom-media --sm-gt /* sm超え */ (min-width: $breakpoint-sm);
@custom-media --md-gt /* md超え */ (min-width: $breakpoint-md);
@custom-media --lg-gt /* lg超え */ (min-width: $breakpoint-lg);
@custom-media --flex (max-width: resolve($page-base-width + $page-viewport-side-margin * 2));
@custom-media --hr /* high resolution */ (min-resolution: 2dppx);
@custom-media --vp-lte /* viewport以下 */ (max-width: $page-viewport-width-02);

// # 汎用マージン
//
$margin-narrow-xs: 5px;
$margin-narrow-sm: 10px;
$margin-narrow-md: 10px;
$margin-narrow-lg: 15px;
$margin-narrow-xl: 15px;

$margin-wide-xs: 30px;
$margin-wide-sm: 50px;
$margin-wide-md: 50px;
$margin-wide-lg: 80px;
$margin-wide-xl: 80px;

// # カラム
//
$card-cols: 3;
$card-cols-sm: 3;
$card-cols-xs: 1;
$card-cols-side-margin: 30px;
$card-cols-side-sm-margin: 15px;
$card-cols-side-xs-margin: 0px; /* stylelint-disable-line */ /* for @media rules */
$card-cols-vertical-margin: 40px;
$card-cols-vertical-sm-margin: 20px;
$card-cols-vertical-xs-margin: 30px;

// # メインカラムセンタリング
@define-mixin flexible-width-body {
	max-width: resolve($page-viewport-width + $page-viewport-side-margin * 2);
	padding-left: $page-viewport-side-margin;
	padding-right: $page-viewport-side-margin;
	width: 100%;
	margin: 0 auto;

	@media (--xs) {
		padding-left: $page-viewport-side-margin-xs;
		padding-right: $page-viewport-side-margin-xs;
		min-width: 0;
	}
}
// sp
$sp-contents-padding: 1.5rem;

@define-mixin flexible-width-main-conntents {
	width: $page-base-width;
	// @media (max-width: resolve($page-base-width + $page-viewport-side-margin * 2)) {
	// 	max-width: resolve($page-base-width + $page-viewport-side-margin * 2);
	// 	padding-left: $page-viewport-side-margin;
	// 	padding-right: $page-viewport-side-margin;
	// 	width: 100%;
	// }
	@media (--xs) {
		padding-left: $page-viewport-side-margin-xs;
		padding-right: $page-viewport-side-margin-xs;
		width: 100%;
		min-width: 0;
	}
}
