@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;700&display=swap');
@font-face {
	font-family: YuGothicM;
	src: local("Yu Gothic Medium");
}

@font-face {
	font-family: HelveticaHack;
	font-style: normal;
	font-weight: normal;
	src:
		local("HelveticaNeue"),
		url("../font/HelveticaNeue.woff"),
		local("Helvetica");
}

@font-face {
	font-family: HelveticaHack;
	font-style: normal;
	font-weight: bold;
	src:
		local("HelveticaNeue-Bold"),
		url("../font/HelveticaNeue.woff"),
		local("Helvetica-Bold");
}

@font-face {
	font-family: icons;
	font-style: normal;
	font-weight: normal;
	src: url("../font/icons.eot");
	src:
		url("../font/icons.eot?#iefix") format("embedded-opentype"),
		url("../font/icons.woff") format("woff"),
		url("../font/icons.ttf") format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');

$font-family-primary: "FOT-筑紫ゴシック Pr5 M", "YuGothic", "YuGothicM", "游ゴシック", "メイリオ", "Meiryo", sans-serif;
$font-family-primary-bold: "FOT-筑紫ゴシック Pro B", "YuGothic", "游ゴシック", "メイリオ", "Meiryo", sans-serif;
$font-family-secondary: "Oswald", $font-family-primary;
$font-family-notosans: "Noto Sans JP", $font-family-primary;
$font-family-tertiary: "HelveticaHack", "Open Sans", "Arial", $font-family-primary;
$font-family-mincho: "FOT-筑紫明朝 Pr6 R", "YuMincho", serif;
$font-family-heading: $font-family-secondary;
$font-family-helvetica: "HelveticaNeue-UltraLight", "Helvetica Neue UltraLight", "Helvetica Neue", "Arial", "Helvetica", "sans-serif";
$font-family-helvetica-normal: "Helvetica", "sans-serif";
$font-family-yumincho:"游明朝体", "Yu Mincho", "YuMincho", "Noto Serif JP", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
$font-family-yugothic: "YuGothic", "YuGothicM", "游ゴシック", "メイリオ", "Meiryo";
$font-family-yugothic2: "YuGothic", "游ゴシック", "メイリオ", "Meiryo","ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$font-family-hiragino: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$font-family-barlow: "Barlow", sans-serif;
$font-family-icons: icons;

@define-mixin icon {
	display: inline-block;
	font-family: icons;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	speak: none;
	text-align: left;
	text-indent: 0;
	text-rendering: auto;
	text-transform: none;
	vertical-align: middle;
	white-space: nowrap;
}

$glyph-space: "\0020";
$glyph-email: "\E100";
$glyph-youtube: "\E111";
$glyph-phone: "\E140";
$glyph-search: "\E150";
$glyph-rss: "\E160";
$glyph-external: "\E170";
$glyph-facebook: "\E200";
$glyph-instagram: "\E201";
$glyph-twitter: "\E202";
$glyph-line: "\E203";
$glyph-googleplus: "\E204";
$glyph-plus: "\E800";
$glyph-minus: "\E801";
$glyph-times: "\E802";
$glyph-arrow-right: "\E900";
$glyph-arrow-right-triangle: "\E901";
$glyph-arrow-right-narrow: "\E902";


