// 共通スタイル

/* Reset-----------------------------------------------------------*/

html, body, div, span, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, address, big, code, em, font, img,  small, strong,
ol, ul, li, form, label, table,  tr, th, td, dl, dd, dt,
article, aside, footer, header, menu, nav, section, summary, textarea,main,
figure,figcaption
{margin: 0;padding: 0;border: 0;outline: 0;font-size: 100%;vertical-align: baseline;background: transparent;}
i{font-style:normal;}
.clx:after {clear: both;content: ' ';display: block;font-size: 0;line-height: 0;visibility: hidden;width: 0;height: 0;}
.clx { display: block; }
* html .clx { height: 1%; }
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display: block;}
html {-webkit-text-size-adjust:none;-webkit-touch-callout: none;}
a {outline:none;text-decoration:none;}
iframe {border:none;}
ul, ol { list-style: none;}
img { border: none; vertical-align:bottom;max-width: 100%;height: auto;width /***/:auto; display:inline-block;}
:focus { outline: 0;}
table { border-collapse: collapse;border-spacing:0;font-size:inherit;font:100%;}
h1, h2, h3, h4, h5, h6 {line-height:1.4;}
html {
	height: 100%;
}
body {
font: 15px "Helvetica Neue","helvetica","�q���M�m�p�S Pro W3","Hiragino Kaku Gothic Pro","Verdana",Meiryo,"�l�r �o�S�V�b�N","MS PGothic",sans-serif;
*font-size: small;
*font: x-small;
color:#FFF;
letter-spacing: 0.03em;
background:#000;
height: 100%;
}

/* Common -----------------------------------------------------------*/
body {overflow-x: hidden; position: relative;}

#fade {width:100%;height:100%;z-index:5555;background:url(../img/bg_loading.gif)center repeat;position:fixed;}
/*#fade:before { content:url(../img/logo_min.png);position:absolute; top:50%; left:50%; margin:-39px 0 0 -150px;}*/

/* text -------------------------------------*/
a {
	color: #333;
	opacity: 1.0;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -o-transition: .5s;
    -ms-transition: .5s;
    transition: .5s;
}
a:hover {
	opacity: 0.7;
}
/* a.noefect:hover {
    -webkit-transition: .0s;
    -moz-transition: .0s;
    -o-transition: .0s;
    -ms-transition: .0s;
    transition: .0s;
} */
a.nofade:hover {opacity: 1.0;}
p {line-height: 1.6;}

/* color -------------------------------------*/


/* web font -------------------------------------*/
h2,h3,header nav li,#copy , .wfont, #home .arrow{
	font-family: 'Dosis', sans-serif;
	font-weight:500;
	letter-spacing:0.1em;
}

/* inner -------------------------------------*/
.inner{ width:1000px; margin:0 auto; position:relative;}
.pc{}
.sp{ display:none;}

/* header -------------------------------------*/
header { position:fixed; width:100%; z-index:100;}

.headroom {position: fixed;top: 0;left: 0;right: 0;transition: all .2s ease-in-out;}
.headroom--unpinned {top: -100px;}
.headroom--pinned {top: 0;}

	header .inner{height:90px;width: 1200px;}
	header h1{ padding:1.5em 0 0;}
	header h1, header h1 a{width:180px;height:47px}
		header h1 a{background:url(../img/logo.png)left top no-repeat;	background-size:180px 47px;}
	header nav{ width:100%;}
		header nav ul{ position:absolute; right:0; top:1.7em;}
		header nav li{ display:inline; padding:0 0 0 1em; font-size:80%;}
			header nav li:first-child{ padding:0; background:none;}
		header nav li a{ color: #FFF;}
		header nav li a .txt-red{ font-weight: bold; color: #851d1a; }

		.sp_menu { display: none; }

/* footer -------------------------------------*/
footer { text-align: center; overflow:hidden; background:#000;}
	footer .inner{ 
		padding:1em;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;}
	#copy {font-size:67%;}

@media screen and (max-width:1050px) {
	.inner{ width:95%;}
}

@media screen and (max-width:840px) {
/* inner -------------------------------------*/
header .inner{ width:100%;}
	main .inner{ width:93%;}

/* header -------------------------------------*/
header {height:50px;background:#A7161D;}
	header .inner{ height:100%;}
	header h1{ padding:10px 10px 0;}
	header h1, header h1 a{width:115px;height:30px;}
		header h1 a{background:url(../img/logo.png)left top no-repeat;	background-size:115px 30px;}

	.dl-menuwrapper button {width:50px;height:50px;background:none;color:#FFF;font-size:140%;cursor:pointer;border:none;outline:0;padding:0; position:absolute; right:0; top:0;background:url(../img/nav.png)center center no-repeat;background-size:30%;}
		.dl-menuwrapper ul, .no-touch .dl-menuwrapper li a:hover { z-index:9999; color:#FFF;}
		.dl-menuwrapper li {position:relative;border-top:1px solid rgba(65, 65, 65, .1);}
			.dl-menuwrapper li a {display:block;position:relative;outline:0}

	.dl-menuwrapper .dl-menu {margin:50px 0 0; position:absolute;width:100%;opacity:0;pointer-events:none;-webkit-transform:translateY(-10px);-moz-transform:translateY(-10px);transform:translateY(-10px);-webkit-backface-visibility:hidden;-moz-backface-visibility:hidden;backface-visibility:hidden;}
		.dl-menuwrapper .dl-menu.dl-menu-toggle {-webkit-transition:all .3s ease;-moz-transition:all .3s ease;transition:all .3s ease;}
		.dl-menuwrapper .dl-menu.dl-menuopen {opacity:1;pointer-events:auto;-webkit-transform:translateY(0);-moz-transform:translateY(0);transform:translateY(0);}

	header nav{ position:absolute; right:0; bottom:0; top:0;}
	header nav ul{ position:absolute; right:0; bottom:0; top:0;}
		header nav li, header nav li:first-child{ display:block; background:#A7161D;padding:0.7em 1em;}
		header nav li a .txt-red{ color: #fff; }

		.sp_menu { display: block; position: absolute; top: 0.9em; right: 14%; text-align: center; }
		.sp_menu a { font-size: 12px; font-weight: bold; line-height: 1.5; color: #fff; text-align: center; }
}



/* common -------------------------------------*/

h2{ text-align:center;font-size:250%; margin:0 0 0.5em;}
h3{ text-align:center;font-size:180%; margin:0 0 0.5em;}
main[role="main"] {text-align:center;}

body{}
main #bgvid{position: fixed; top: 0; right: 0; min-width: 100%; min-height: 100%; width: auto; height: auto; z-index: -100;}

/* #home -------------------------------------*/
#home{width:100%;height: 100vh; position:relative;}
	#home .logo {position:absolute;	top:40%;text-align:center; width:100%;}
	#home .arrow {position: absolute;bottom: 1.5em;left:50%;margin:0 0 0 -2em; background:url(../img/arrow01.png) center bottom no-repeat;}
		#home .arrow a{padding:0 0 2em; display:block; color:#FFF;}
/* #about -------------------------------------*/
#about{ padding:10em 0;background:rgba(0, 0, 0, 0.8);display: block;}

/* background:url(../img/bg.jpg)center center no-repeat fixed;background-size:cover; */
	#about p{ text-align:left; margin:0 0 3em;letter-spacing: 0.1em;line-height:210%;}

/* #nariagari -------------------------------------*/
#nariagari{ padding:10em 0 8em; background:rgba(167, 22, 29, 0.8);overflow: hidden;}

	#nariagari h3{ font-size:500%; font-family: "�q���M�m���� ProN W6", "HiraMinProN-W6", "HG����E", "�l�r �o����", "MS PMincho", "MS ����", serif; letter-spacing:0.4em;}

/* #service -------------------------------------*/
#service{ padding:10em 0;background:rgba(0, 0, 0, 0.6);display: block;}

	#service ul{padding:2em 0 0;}
	#service li{ width:33%; text-align:center; display:inline-table;}


/* #company -------------------------------------*/
#company{ padding:10em 0 0;background:rgba(0, 0, 0, 0.95);display: block;}

	#company p{ padding:0 0 3em; color: #fff;}
	a[href^="tel"]{
	    color:inherit;
	    text-decoration:none;
	}
		#company p span{ display:block;}
	#company dl{letter-spacing: 0.1em;line-height:210%;}
		#company div.wow{padding:0 0 5em; overflow:hidden;}

		#company dl dt{ float:left; clear:left;width:20%; text-align:right; padding:0 1em 0.5em 0;box-sizing: border-box;-moz-box-sizing: border-box;-webkit-box-sizing: border-box;}
		#company dl dt:after{ content:":";padding:0 0 0 1em;}

		#company dl dd{ float:left;width:80%; padding:0 0 0.5em 0;text-align:left;}

	#company #map{ padding: 0; width:100%; height:400px;}

/* #recruit -------------------------------------*/
#recruit{ padding:10em 0;background:rgba(255, 255, 255, 0.8);display: block; color:#000;}

	#recruit .inner div{width:50%; float:left;padding:0 1em;box-sizing: border-box;-moz-box-sizing: border-box;-webkit-box-sizing: border-box;}

	#recruit dl{ font-size:87%;letter-spacing: 0.1em;line-height:210%;}
		#recruit dl dt{ float:left; clear:left;width:20%; text-align:right; padding:0 1em 1em 0;box-sizing: border-box;-moz-box-sizing: border-box;-webkit-box-sizing: border-box;}
		#recruit dl dt:after{ content:":";padding:0 0 0 1em;}

		#recruit dl dd{ float:left;width:80%; padding:0 0 1em 0;text-align:left;}

/* #contact -------------------------------------*/
#contact{ padding:10em 0 14em;background:rgba(255, 255, 255, 1);display: block; color:#000;}


/* .bnr -------------------------------------*/
.bnr{ padding:3em 0 0;}
.bnr img{ width:320px;}
/* mordal -------------------------------------*/
#inline-wrap {
	position: relative;
	margin:10px auto;
	max-width: 700px;
	background: #fff;
	padding: 60px 20px;
}
#inline-wrap h2 {
	font-weight: bold;
	font-size: 38px;
	margin: 0 0 30px;
	color: #0e0e0e;
}
#inline-wrap h3 {
	font-weight: bold;
	font-size: 22px;
	margin: 0 0 22px;
	color: #0e0e0e;
}
#inline-wrap .image img {
	width:100%;
	max-width: 600px;
}
#inline-wrap .image,
#inline-wrap .popup-modal-dismiss {
	text-align: center;
}

#inline-wrap table {
	border: 1px solid #b5b5b6;
	font-size: 14px;
	line-height: 1.71;
	color: #0e0e0e;
	width: 600px;
	margin: 0 auto 30px;
}
#inline-wrap th {
	background: #efefef;
	text-align: left;
	width: 120px;
	padding: 14px 30px;
	border-bottom: 1px solid #b5b5b6;
}
#inline-wrap td {
	background: #fff;
	padding: 14px 30px;
	border-bottom: 1px solid #b5b5b6;
}
#inline-wrap .txt-comments {
	font-size: 14px;
	color: #0e0e0e;
	text-align: center;
	margin: 0 auto 50px;
}
#inline-wrap .ttl-mail {
	font-size: 22px;
	font-weight: bold;
	margin: 0 auto 10px;
}
#inline-wrap .txt-mail {
	font-size: 14px;
	color: #0e0e0e;
	text-align: center;
	color: #861f1c;
}
#inline-wrap .txt-mail span {
	display: block;
	font-size: 24px;
	font-weight: bold;
}
#inline-wrap .mfp-close {
	top: 5px;
	right: 5px;
}

/* clear clearfix */
.clear {clear:both;}
.clearleft {clear:left;}
.clearfix {zoom:1;}
.clearfix:after {
  content: ""; 
  display: block; 
  clear: both;
}

.pc_only {display: block;}
.sp_only {display: none;}

@media screen and (max-width:840px) {
.pc_only {display: none;}
.sp_only {display: block;}


/* Common -----------------------------------------------------------*/

.pc{ display:none;}
.sp{ display:inline;}

h2{ text-align:center;font-size:180%; margin:0 0 0.5em;}
h3{ text-align:center;font-size:140%; margin:0 0 0.5em;}

body{background:none;}

body::before {
  background:url("../img/bg_sp.jpg") no-repeat left top;
  background-size: cover;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 108px;
  content: "";
  z-index: -1;
}
main #bgvid{display:none;}

/* #home -------------------------------------*/
#home{}
	#home .logo {top:37%;}
		#home .logo img{ width:70%;}
	#home .arrow {bottom: 5em;}
/* #about -------------------------------------*/
#about{ padding:5em 0;}

	#about p{line-height:180%;}
/* #nariagari -------------------------------------*/
#nariagari{ padding:5em 0 4em;}

	#nariagari h3{ font-size:240%; font-family: "�q���M�m���� ProN W6", "HiraMinProN-W6", "HG����E", "�l�r �o����", "MS PMincho", "MS ����", serif; letter-spacing:0.4em;}

/* #service -------------------------------------*/
#service{ padding:5em 0;}

	#service ul{padding:0;}
	#service li{ width:100%; text-align:left; display:block;}
		#service li img{ width:25%; display:inline; vertical-align:middle;}
		#service li	span{  display:inline;letter-spacing: 0.05em;line-height:1.5em;}


/* #company -------------------------------------*/
#company{ padding:5em 0 0;}

	#company dl{letter-spacing: 0.1em;line-height:180%;}

		#company div.wow{padding:0 0 2em; overflow:hidden;}

		#company dl dt{ float:none; clear:none;width:100%; text-align:left; padding:0 0 0.5em 0;font-weight:bold;}
		#company dl dt:after{ content:"";padding:0;}

		#company dl dd{ float:none;width:100%;padding:0 0 1.5em 0;}

	#company #map{}

/* #recruit -------------------------------------*/
#recruit{ padding:5em 0;}

	#recruit .inner div{width:100%; float:none;padding:0;}

	#recruit dl{letter-spacing: 0.1em;line-height:160%;}
		#recruit dl dt{ float:none; clear:none;width:100%; text-align:left; padding:0 0 0.5em 0; font-weight:bold;}
		#recruit dl dt:after{ content:"";padding:0;}

		#recruit dl dd{ float:none;width:100%;padding:0 0 2em 0;}

/* #contact -------------------------------------*/
#contact{ padding:5em 0 8em;}

/* .bnr -------------------------------------*/
.bnr{ padding:2em 0 0;}
.bnr img{ width:300px;}

/* mordal -------------------------------------*/
#inline-wrap {
	margin:10px auto;
	max-width: 100%;
	padding: 30px 14px;
}
#inline-wrap h2 {
	font-size: 24px;
	margin: 0 0 10px;
}
#inline-wrap h3 {
	font-size: 18px;
	margin: 0 0 12px;
}

#inline-wrap table {
	font-size: 12px;
	line-height: 1.71;
	color: #0e0e0e;
	width: 100%;
	margin: 0 auto 18px;
	overflow: hidden;
}
#inline-wrap th {
	width: 30%;
	padding: 8px;
}
#inline-wrap td {
	padding: 8px;
}
#inline-wrap .txt-comments {
	font-size: 12px;
	text-align: left;
	margin: 0 auto 20px;
}
#inline-wrap .ttl-mail {
	font-size: 18px;
	font-weight: bold;
	margin: 0 auto 2px;
}
#inline-wrap .txt-mail {
	font-size: 12px;
	text-align: center;
}
#inline-wrap .txt-mail span {
	font-size: 18px;
}


}
