@charset "UTF-8";
/* ===================================================================
CSS information
 file name  :  recruit.css
 style info :  ashigaru：求人ページスタイル
=================================================================== */

/*--------------------------------
common
--------------------------------*/
body {
	background: url(../img/bg_recruit.jpg) repeat center top fixed;
}
* {box-sizing: border-box;}

#transfer a,
#everyday a,
section.requirements a {color:$accent-color;}
F
a.blank::after {
	font-family: "Font Awesome 5 Free";
	content: "\f24d";
	margin-left: 4px;
}
.marker {
	background: linear-gradient(transparent 70%, $marker-color 70%);
}
img {
    image-rendering: -webkit-optimize-contrast;
}
@media screen and (min-width:330px) and (max-width:768px) {
	body {
		background: none;
	
		&::before {
			content:"";
			display:block;
			position:fixed;
			top:0;
			left:0;
			z-index:-1;
			width:100%;
			height:100vh;
			background: url(../img/bg_recruit.jpg) center/cover no-repeat; /*fixedをトル！*/
			-webkit-background-size:cover;/*Android4*/
		  }
	
		  &.active::before {
			background-color: rgba(0,0,0,0.60);
			background-image: none;
		  z-index:100;
		}
	}
	
	section.recruit {
		width: calc(704/750*100vw);
		margin: 0 auto;
	}
}

/*--------------------------------
header
--------------------------------*/
header {
	background-color: $darkest-color;

	.inner {
		width: 100%;
		max-width: 1200px;
	}

	h1 {
		position: relative;
		z-index: 2;

		a {
			background:url(../img/logo_recruit.png) left top no-repeat;
			background-size: contain;
			@media screen and (max-width:840px) {
				background-size:115px 30px;
			}
		}
	}

	nav {
		ul {
			right: 10px;
			@media screen and (max-width:840px) {
				right: 0px;
			}
		}

		&#nav {
			z-index: 1;

			#dl-menu_recruit .dl-trigger {
				z-index: 3;
			}
		}
	}
}
@media screen and (min-width:330px) and (max-width:768px) {
	header {
		h1 {
			a {
				background:url(../img/logo_recruit.png)left top no-repeat;
				background-size:115px 30px;
			}
		}
	
		nav {
			ul {
				right: 0px;
			}
		}
	}
}
body#form #gnav,
body#form .navToggle{
	display: none;
}
#gnav li:nth-last-child(n+2) {
	padding-right: 20px;
	border-right: 2px $gray-color solid;
}
#gnav li i {
	color: $gray-color;
}
@media screen and (min-width:330px) and (max-width:768px) {
	.fa-bars::before {
		content: "";
	}
}

/*--------------------------------
求人用ハンバーガーメニュー
--------------------------------*/
@media screen and (min-width:330px) and (max-width:768px) {
	/* 求人用ハンバーガーメニューカスタム */
	.dl-menuwrapper {
		.dl-menu {
			transform: translateY(-100%);
			transition: all 0.5s;

			&.active {
				opacity:1;
				pointer-events:auto;
				-webkit-transform:translateY(0);
				-moz-transform:translateY(0);
				transform:translateY(0);
			}
		}
	}
	#gnav {
		li:nth-last-child(n+2) {
			border-right: none; /* PC用のナビの罫線打ち消し用 */
		}
	}

	/* ハンバーガーメニュー */
	.navToggle {
		display: block;
		position: fixed;    /* bodyに対しての絶対位置指定 */
		right: 4px;
		top: 5px;
		width: 40px;
		height: 50px;
		cursor: pointer;
		z-index: 3;
		text-align: center;

		span {
			display: block;
			position: absolute;    /* .navToggleに対して */
			width: 30px;
			border-bottom: solid 3px $gray-color02;
			-webkit-transition: .35s ease-in-out;
			-moz-transition: .35s ease-in-out;
			transition: .35s ease-in-out;
			left: 6px;

			&:nth-child(1) {
				top: 9px;
			}

			&:nth-child(2) {
				top: 18px;
			}

			&:nth-child(3) {
				top: 27px;
			}

			&:nth-child(4) {
				border: none;
				color: $gray-color02;
				font-size: 9px;
				font-weight: bold;
				top: 34px;
			}
		}

		&.active {
			span {
				/* 最初のspanをマイナス45度に */
				&:nth-child(1) {
					top: 18px;
					left: 6px;
					-webkit-transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}
				/* 2番目と3番目のspanを45度に */
				&:nth-child(2),&:nth-child(3)  {
					top: 18px;
					-webkit-transform: rotate(45deg);
					-moz-transform: rotate(45deg);
					transform: rotate(45deg);
				}
			}
		}
	}
}



/*--------------------------------
index_header
--------------------------------*/
.index_header {
	width: 100%;
	height: auto;
	box-sizing: border-box;
	background: url(../img/bg_index_pc.png) center center no-repeat;
	background-size: cover;
	margin: 0px 0 70px;
	padding: 200px 0 170px;
	text-align: center;
	box-shadow:0px 0px 10px 3px $gray-color03;
}
@media screen and (min-width:330px) and (max-width:768px) {
	.index_header {
		width: 100%;
		height: auto;
		background: url(../img/bg_index_sp.png) no-repeat bottom center;
		background-size: cover;
		margin: 0px 0 40px;
		padding: 80px 10% 40px;
		box-sizing: border-box;
	}
}

/*--------------------------------
main
--------------------------------*/
main {
	z-index:99;
	width: 1000px;
	margin: 0 auto;
	padding-top: 170px;
	color: $darkest-color;
	display: block;

	&.index_section {
		padding-top: 0px;
	}
}
@media screen and (min-width:330px) and (max-width:768px) {
	main {
		width: auto;
		max-width: 100%;
		padding-top: 80px;
		overflow: hidden;
	}
}

h1.title {
	font-size: 0;
	line-height: 0;
}
h2.section_ttl {
	font-size: 30px;
	line-height: 50px;
	font-weight: bold;
	margin-bottom: 40px;

	span {
		padding: 0 34px;
		display: inline-block;
		background-image:url(../img/ico_ashigaru.png) ,url(../img/ico_ashigaru.png);
		background-repeat: no-repeat, no-repeat;
		background-position: left top, right top;
		min-height: 50px;
	}
}
@media screen and (min-width:330px) and (max-width:768px) {
	h2.section_ttl {
		font-size: 20px;
		line-height: 25px;
		margin-bottom: 20px;

		span {
			padding: 0 20px;
			background-size: 12px 25px;
			min-height: 25px;
		}
	}
}
@media screen and (max-width:360px) {
	h2.section_ttl {
		font-size: 18px;
		line-height: 22px;
	}
}

.btn_area {
	margin: 0 auto 100px;
}
@media screen and (min-width:330px) and (max-width:768px) {
	.btn_area {
		margin: 0 auto 40px;
	}
}

/*--------------------------------
section.recruit
--------------------------------*/
section.recruit {
	margin: 0 auto 120px;

	.p_container {
		margin: 0 auto 40px;
	}

	p {
		font-size: 18px;
		line-height: 36px;
		margin-bottom: 10px;
	}
}
@media screen and (min-width:330px) and (max-width:768px) {
	section.recruit {
		margin-bottom: 60px;
	
		p {
			font-size: 16px;
			line-height: 28px;
			margin-bottom: 10px;
		}
	}
}

section#careerup,
section#transfer,
section#curriculum,
section#everyday,
section#outline,
section#map_address {
	margin-top: -100px;
	padding-top: 100px;
}

/*--------------------------------
section.intro
--------------------------------*/
section.intro {
	margin: 0 auto 80px;

	h2.recruit_ttl {
		text-align: left;
		font-size: 42px;
		font-weight: bold;

		span {
			font-size: 34px;
			margin-left: 20px;
			font-weight: normal;
		}
	}

	.mv {
		margin: 0 auto 30px;
		box-shadow:0px 0px 10px 3px $gray-color03;
	}

	p {
		font-size: 18px;
		line-height: 32px;
		margin-bottom: 14px;

		&.tel_num {
			font-size: 36px;

			span {
				margin-right: 6px;
			}

			a:hover {
				color:$accent-color;
			}
		}
	}
}
@media screen and (max-width:840px) {
	section.intro {
		margin: 0 auto 40px;

		h2.recruit_ttl {
			font-size: 22px;

			span {
				font-size: 17px;
				margin-left: 10px;
			}
		}

		.mv {
			margin: 0 calc(-23/750*100vw) 20px;
			box-shadow:0px 0px 10px 3px $gray-color03;
		}

		p {
			font-size: 16px;
			line-height: 28px;
			margin-bottom: 8px;
		}
	}
}

/* PCの時、電話番号を無効 */
@media screen and (min-width:841px) {
a[href^="tel:"] {
    pointer-events: none;
}
}

/*--------------------------------
nav#index_nav
--------------------------------*/
#index_nav {
	width: 100%;
	margin-bottom: 120px;
	text-align: center;

	h3 {
		width: 100%;
		padding-bottom: 10px;
		margin-bottom: 20px;
		border-bottom: $darkest-color solid 2px;
		font-size: 24px;
		font-weight: bold;
	}

	ul {
		width: 92%;
		margin: 0 auto;
		text-align: left;

		li {
			margin: 0 10px 10px;
			position: relative;
			display: inline-block;
			padding: 0 0 0 16px;
			color: $darkest-color;
			vertical-align: middle;
			text-decoration: none;
			font-size: 18px;

			&::before,
			&::after {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				content: "";
				vertical-align: middle;
			}

			&::before {
				width: 12px;
				height: 12px;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				background: $accent-color;
			}

			&::after {
				left: 4px;
				top: -1px;
				width: 3px;
				height: 3px;
				border-top: 1px solid $lightest-color;
				border-right: 1px solid $lightest-color;
				-webkit-transform: rotate(135deg);
				transform: rotate(135deg);
			}
		}
	}
}
@media screen and (max-width:840px) {
	#index_nav {
		width: 100%;
		margin-bottom: 40px;
		text-align: left;

		h3 {
			width: 90%;
			margin: 0 auto 20px;
			font-size: 16px;
		}

		li {
			font-size: 16px;
			margin-bottom: 6px;
		}	
	}
}

/*--------------------------------
section.careerup
--------------------------------*/
section.careerup {
	table.career_test {
		width: 100%;
		margin: 20px 0;
		font-size: 16px;
		line-height: 28px;
		border: $gray-color04 solid ;
		border-width: 1px 0px 0px 1px;
	
		th {
			background-color: $gray-color02;
			width: 6em;
		}
	
		td {
			background-color: $lightest-color;
		}
	
		th,
		td {
			padding: .5em;
			border: $gray-color04 solid ;
			border-width: 0px 1px 1px 0px;
		}
	}

	.careerup_image {
		width: 100%;
		margin: 100px 0 30px;
		padding-top: 130px;
		position:relative;

		&>h3 {
			position: absolute;
			top: -20px;
			text-align: center;
			width: 100%;

			span {
				font-size: 24px;
				font-weight: bold;
				border: $secondary-color solid;
				border-width: 0px 4px 0px;
				padding: 0 .5em;
			}
		}

		ul {
			width: 100%;
			background: url(../img/bg_carrerup_pc.png) no-repeat bottom center;

			li {
				width: 188px;
				margin-right: 15px;
				padding: 0px 10px 20px;
				float: left;
				position: relative;

				&:nth-of-type(1) {
					background-color: #f9e9c7;
					margin-top: 260px;
				}

				&:nth-of-type(2) {
					background-color: #f9d995;
					margin-top: 196px;
				}

				&:nth-of-type(3) {
					background-color: #facb65;
					margin-top: 130px;
				}

				&:nth-of-type(4) {
					background-color: #fbbc34;
					margin-top: 60px;
				}

				&:nth-of-type(5) {
					background-color: #fcad03;
					margin-top: 0px;
				}

				&::before {
					content: "";
					width: 168px;
					position: absolute;
				}

				&:nth-of-type(1)::before {
					background: url(../img/ico_ashigaru_lv01.png) no-repeat center bottom;
					height: 60px;
					top: -60px;
				}

				&:nth-of-type(2)::before {
					background: url(../img/ico_ashigaru_lv02.png) no-repeat center bottom;
					height: 94px;
					top: -94px;
				}
				&:nth-of-type(3)::before {
					background: url(../img/ico_ashigaru_lv03.png) no-repeat center bottom;
					height: 106px;
					top: -106px;
				}
				&:nth-of-type(4)::before {
					background: url(../img/ico_ashigaru_lv04.png) no-repeat center bottom;
					height: 100px;
					top: -100px;
				}
				&:nth-of-type(5)::before {
					background: url(../img/ico_ashigaru_lv05.png) no-repeat center bottom;
					height: 130px;
					top: -130px;
				}
				&:last-child {
					margin-right: 0;
				}

				h2 {
					font-size: 16px;
					font-weight: bold;
					color: $lightest-color;
					background-color: $secondary-color;
					margin: 0 -10px 14px;
					padding: 4px 0;
				}

				h3 {
					font-size: 13px;
					font-weight: bold;
					text-align: left;
					margin: 0 -2px 10px;
					padding-left: 12px;
					background: url(../img/ico_ashigaru_mini.png) no-repeat left center;
					background-size: 9px 18px;

					&:nth-of-type(n+2) {
						margin-top: 14px;
					}
				}

				p {
					font-size: 13px;
					line-height: 23px;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0px;
					}
				}
			}
		}
	}
}
@media screen and (max-width:840px) {
	section.careerup {
		table.career_test {
			margin: 20px 0;
			font-size: 12px;
			line-height: 22px;
		}

		.careerup_image {
			padding-top: 0px;
			margin-top: 40px;

			ul {
				background: none;

				li {
					width: 100%;
					margin-right: 0;
					padding: 0px 10px 20px;
					float: none;

					&:nth-of-type(1) {
						margin-top: 50px;
					}

					&:nth-of-type(2) {
						margin-top: 70px;
					}

					&:nth-of-type(3) {
						margin-top: 80px;
					}

					&:nth-of-type(4) {
						margin-top: 80px;
					}

					&:nth-of-type(5) {
						margin-top: 90px;
					}

					&::before {
						width: 100%;
						margin: 0 -10px;
					}

					&:nth-of-type(1)::before {
						background-size: auto 30px;
						height: 30px;
						top: -30px;
					}

					&:nth-of-type(2)::before {
						background-size: auto 45px;
						height: 45px;
						top: -45px;
					}

					&:nth-of-type(3)::before {
						background-size: auto 52px;
						height: 52px;
						top: -52px;
					}

					&:nth-of-type(4)::before {
						background-size: auto 48px;
						height: 48px;
						top: -48px;
					}

					&:nth-of-type(5)::before {
						background-size: auto 63px;
						height: 63px;
						top: -63px;
					}
				}
			}

			&>h3 {
				position: static;
				margin-bottom: 0;
				font-size: 12px;

				span {
					font-size: 20px;
				}
			}

			ul {
				li {
					h3 {
						font-size: 16px;
						line-height: 28px;
						background-size: 7px 15px;
						margin: 0 0 4px;
					}

					p {
						font-size: 14px;
						line-height: 24px;
					}
				}
			}
		}
	}
}

/*--------------------------------
section.transfer
--------------------------------*/
section.transfer {
	table.parent_company {
		width: 100%;
		margin: 20px 0;
		font-size: 16px;
		line-height: 28px;
		border: $gray-color04 solid ;
		border-width: 0px 0px 1px 0px;
	}

	table.parent_company {
		tr {
			width: 12em;
			border: $gray-color04 solid ;
			border-width: 1px 0px 0px 0px;
		}

		th {
			width: 12em;
		}

		th,
		td {
			vertical-align: top;
			padding: 20px 10px 10px;
		}

		td {
			h3 {
				font-size: 20px;
				font-weight: bold;
				text-align: left;
			}

			dl {
				margin-bottom: 10px;
				width: 100%;

				dt {
					font-size: 16px;
					line-height: 22px;
					font-weight: bold;
					float: left;
					padding-top: 2px;
				}

				dd {
					font-size: 16px;
					line-height: 24px;
					margin-left: 5em;
					margin-bottom: 4px;
				}
			}
		}
	}
}
@media screen and (max-width:840px) {
	section.transfer {
		table.parent_company {
			width: 100%;
			margin: 20px 0;

			th, td {
				display: inline-block;
				width: 100%;
				border-bottom: none;
			}

			th {
				padding-bottom: 20px;
			}

			td {
				padding-top: 0px;

				h3 {
					font-size: 17px;
				}

				dl {
					dt {
						font-size: 14px;
						line-height: 20px;
						padding-top: 0px;
					}

					dd {
						font-size: 14px;
						line-height: 20px;
					}
				}
			}

			th.logo_ucai {
				img {
					width: 100%;
					max-width: 121px;
				}
			}

			th.logo_dzero {
				img {
					width: 100%;
					max-width: 152px;
				}
			}

		}
	}
}

/*--------------------------------
section.curriculum
--------------------------------*/
section.curriculum {
	#curriculum_flow {
		width: 100%;
	}

	#curriculum_flow>li {
		width: 100%;
		padding: 40px 90px 10px;
		margin-bottom: 110px;
		border: #bbbcbc solid 2px;
		background-color: rgba(253,237,202,0.5);
		position: relative;

		&:nth-last-child(n+2)::after {
			content: "";
			position: absolute;
			bottom: -80px;
			left: 45%;
			margin-left: -10px;
			display: block;
			width: 0px;
			height: 0px;
			border-style: solid;
			border-width: 50px 60px 0 60px;
			border-color: $accent-color transparent transparent transparent;
			opacity: 0.5;
		}

		& h2 {
			font-size: 24px;
			line-height: 1.25;
			font-weight: bold;
			color: $accent-color;
			margin-bottom: 20px;
			text-align: left;
		  -webkit-font-smoothing: antialiased;
		  -moz-osx-font-smoothing: grayscale;
		}

		p {
			font-size: 16px;
			line-height: 1.8;
			margin-bottom: 30px;
		}

		ul.weekly {
			&>li {
				width: 390px;
				margin-bottom: 30px;

				&:nth-child(odd){
					float: left;
				}

				&:nth-child(even){
					float: right;
				}

				ul>li {
					font-size: 16px;
					line-height: 28px;
					list-style: inside;
					text-indent: -1em;
					padding-left: 1em;
				}
			}
		}

		h3 {
			font-size: 18px;
			line-height: 30px;
			font-weight: bold;
			border-bottom: #b9b9b9 solid 1px;
			padding-bottom: 10px;
			margin-bottom: 10px;
			text-align: left;
		}
	}
}
@media screen and (max-width:840px) {
	section.curriculum {
		#curriculum_flow{
			&>li {
				width: 100%;
				padding: 15px 15px 0px;
				margin-bottom: 60px;

				&:nth-last-child(n+2)::after {
					bottom: -50px;
					left: 40%;
					margin-left: -10px;
					display: block;
					width: 0px;
					height: 0px;
					border-style: solid;
					border-width: 30px 40px 0 40px;
					border-color: $accent-color transparent transparent transparent;
					opacity: 0.5;
				}

				.flow_ttl{
					padding-right: 30px;
					position: relative;

					&::after{
						position: absolute;
						top: 50%;
						right: 0px;
						margin-top: -9px;
						background: url(../img/mark_plus.png) center / 14px no-repeat;
						content: "";
						width: 18px;
						height: 18px;
						transition: .4s;
					}

					&.open::after {
						background: url(../img/mark_minus.png) center / 14px no-repeat;
						transform: rotate(180deg);
					}

					h2 {
						font-size: 14px;
						line-height: 1.4;
						margin-bottom: 10px;
					}

					p {
						font-size: 12px;
						margin-bottom: 15px;
					}
				}

				ul.weekly {
					margin-bottom: 15px;

					&>li {
						width: 100%;
						margin-bottom: 15px;

						&:last-child {
							margin-bottom: 0px;
						}

						&:nth-child(odd),
						&:nth-child(even) {
							float: none;
						}

						h3 {
							font-size: 14px;
							padding-bottom: 4px;
							margin-bottom: 10px;
						}

						ul>li {
							font-size: 14px;
							line-height: 24px;
						}
					}
				}
			}
		}
	}
}

/*--------------------------------
section.adoption_list
--------------------------------*/
section.adoption_list {
	margin: 0 auto 80px;

	.adoption_bnr {
		width: 100%;
		margin: 0;
		position: relative;

		&::before {
			position: absolute;
			top: -86px;
			right: 30px;
			z-index: 1;
			content: "";
			background:url(../img/obj_ashigaru_kun.png) no-repeat top right /contain;
			width: 128px;
			height: 129px;
		}

		li {
			position: relative;
			z-index: 2;
			width: 480px;
			margin: 0 20px 20px 0;  /* 複数募集時に必要 */
			/* margin: 0 auto 20px;  単数募集時に必要 */
			float: left;  /* 複数募集時に必要 */
			background-color: $lightest-color;

			&:nth-child(2n) {
				margin: 0 0px 20px 0;
			}

			a, img {
				display: block;
			}
		}
	}
}
@media screen and (max-width:840px) {
	section.adoption_list {
		margin: 0 auto 30px;

		.adoption_bnr {
			width: 100%;
			margin: 0;

			&::before {
				display: none;
			}

			li {
				width: 48.5%;   /* 複数募集時に必要 */
				/* width: 100%;   単数募集時に必要 */
				margin: 0 0 10px 0;
				float: left;

				&:nth-child(2n) {
					float: right;
					margin: 0 0 10px 0;
				}
			}
		}
	}
}

/*--------------------------------
section.requirements
--------------------------------*/
section.requirements {
	margin: 0 auto 80px;

	table#requirements_list {
		margin-bottom: 20px;
		border: solid 1px;
		border-width: 1px 0 0 1px;
		width: 100%;
		text-align: left;

		th, td{
			border: solid 1px;
			border-width: 0 1px 1px 0;
			padding: 30px;
			box-sizing: border-box;
		}

		th{
			width: 25%;
			background-color: $gray-color06;

			span {
				color: $lightest-color;
			}
		}

		td{
			width: 75%;
			background-color: rgba(255,255,255,0.40);
			line-height: 160%;

			i {
				margin-right: 4px;
			}
		}
	}
}
@media screen and (max-width:840px) {
	section.requirements {
		margin: 0 auto 40px;

		table#requirements_list {
			margin-bottom: 20px;
			width: 100%;

			th,td{
				padding: 10px;
			}

			th {
				width: 100%;
				display:block;
				font-size: 12px;
				font-weight: normal;
			}

			td {
				width: 100%;
				display: list-item;
				list-style: none;
				font-size: 14px;
				line-height: 22px;

				p {
					font-size: 14px;
				}

				span {
					font-size: 90%;
					line-height: 50%;
				}
			}
		}
	}
}

section.privacy_box {
	.personal_info {
		i {
			margin-right: 4px;
		}
	}
}
section.requirements {
	table#requirements_list {
		td {
			h3{
				font-size: 15px;
				text-align: left;
			}
		}
	}

	table#requirements_list {
		td {
			p {
				font-size: 15px;
				line-height: 1.8;
			}
		}
	}
}

/*--------------------------------
section.recruit_flow
--------------------------------*/
section.recruit_flow {
	margin: 0 auto 80px;

	ul#flow {
		li {
			width: 1000px;
			padding: 60px;
			margin-bottom: 110px;
			box-sizing: border-box;
			background-color: rgba(255,255,255,0.50);
			position: relative;
			display: inline-block;
			border: $gray-color04 solid 1px;

			&:last-child {
				margin-bottom: 0px;
			}

			&:nth-last-child(n+2)::after {
				content: "";
				position: absolute;
				bottom: -80px;
				left: 45%;
				margin-left: -10px;
				display: block;
				width: 0px;
				height: 0px;
				border-style: solid;
				border-width: 50px 60px 0 60px;
				border-color: $accent-color transparent transparent transparent;
				opacity: 0.5;
			}

			h3 {
				font-size: 24px;
				margin-bottom: 30px;
				text-align: left;
				color: $accent-color;
				font-weight: bold;
			  -webkit-font-smoothing: antialiased;
			  -moz-osx-font-smoothing: grayscale;
			}

			p {
				font-size: 18px;
				line-height: 32px;
			}

			.btn {
				text-align: center;

				a {
					background-color: $accent-color;
					display: inline-block;
					width: 460px;
					box-sizing: border-box;
					padding: 30px 0 24px;
					margin: 0 auto;
					box-shadow: 6px 6px;

					&:active {
						margin: 6px -6px -6px 6px;
						box-shadow: 0px 0px;
					}

					span {
						font-size: 30px;
						line-height: 30px;
						font-weight: bold;
						color: $lightest-color;
					}
				}
			}
		}
	}
}
@media screen and (max-width:840px) {
	section.recruit_flow {
		margin: 0 auto 40px;

		ul#flow {
			li {
				width: 100%;
				padding: 15px;
				margin-bottom: 70px;

				&:nth-last-child(n+2)::after {
					bottom: -50px;
					left: 40%;
					margin-left: -10px;
					display: block;
					width: 0px;
					height: 0px;
					border-style: solid;
					border-width: 30px 40px 0 40px;
					border-color: $accent-color transparent transparent transparent;
					opacity: 0.5;
				}

				h3 {
					font-size: 15px;
					margin-bottom: 10px;
				}

				p {
					font-size: 14px;
					line-height: 24px;

					&:last-child {
						margin-bottom: 0px;
					}
				}

				.btn {
					a {
						background-color: $accent-color;
						display: inline-block;
						width: 100%;
						padding: 18px 0;
						margin: 0 auto;
						box-shadow: 6px 6px;

						span {
							font-size: 20px;
							line-height: 20px;
						}
					}
				}
			}
		}
	}
}

section.recruit {
	.arrow {
		span{
			position: relative;
			display: inline-block;
			padding: 0 0 0 16px;
			color: $darkest-color;
			vertical-align: middle;
			text-decoration: none;
			font-size: 15px;

			&::before,
			&::after{
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				content: "";
				vertical-align: middle;
			}

			&::before{
				left: -6px;
				top: -6px;
				box-sizing: border-box;
				width: 10px;
				height: 10px;
				border: 10px solid transparent;
				border-left: 10px solid $lightest-color;
			}
		}
	}
}

/*--------------------------------
section.everyday
--------------------------------*/
section.everyday {
	margin: 0 auto 120px;

	ul {
		&.everyday-for {
			width: 600px;
			height: auto;
			margin: 0 auto 40px;
			box-shadow:0px 0px 8px 2px $gray-color03;
	
			li {
				width: 600px;
				height: auto;
	
				img {
					width: 100%;
					height: auto;
				}
			}
		}

		&.everyday-nav {
			width: 600px;
			height: auto;
			margin: 0 auto;

			li {
				width: 134px;
				height: auto;
				margin: 0 12px;
				cursor: pointer;
				position: relative;

				&.slick-current {
					cursor: auto;
				}

				span {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display:block;
					background-color: rgba(0, 0, 0, 0.5);
								-webkit-transition: .4s;
								-moz-transition: .4s;
								-o-transition: .4s;
								-ms-transition: .4s;
					transition: .4s;
				}

				&.slick-current span,
				&:hover span {
					border: $accent-color solid 3px;
					background: none;
								-webkit-transition: .4s;
								-moz-transition: .4s;
								-o-transition: .4s;
								-ms-transition: .4s;
					transition: .4s;
				}
			}
		}
	}

	/*左右の矢印の色を変える*/
	.slick-prev:before,
	.slick-next:before {
		color: $accent-color;
	}
}
@media screen and (max-width:840px) {
	section.everyday {
		margin: 0 auto 60px;

		ul {
			&.everyday-for {
				width: 100%;
				margin: 0 auto 10px;
		
				li {
					width: 100%;
				}
			}

			&.everyday-nav {
				width: 100%;
		
				li {
					margin: 0 4px;
				}
			}
		}
	}
}

/*--------------------------------
section.outline
--------------------------------*/
section.outline {
	margin: 0 auto 120px;

	table {
		width: 100%;

		th, td {
			padding: 30px;
			font-size: 18px;
			line-height: 30px;
			@media screen and (max-width:840px) {
				font-size: 14px;
				line-height: 24px;
				display: block;
			}
		}
		th {
			width: calc(380/1000*100vw);
			text-align: left;
			border-bottom: $gray-color05 solid 2px;
			@media screen and (max-width:840px) {
				width: 100%;
				border: none;
				padding: 10px 10px 5px 10px;
			}
		}


		td {
			width: calc(620/1000*100vw);
			border-bottom: #d2d2d2 solid 2px;
			@media screen and (max-width:840px) {
				width: 100%;
				padding: 0px 10px 10px 10px;
			}
		}

	}
}


/*--------------------------------
section.map
--------------------------------*/
section.map {
	margin: 0 auto 120px;

	p {
		text-align: center;
	}

	#map{
		padding: 0;
		width: 1000px;
		max-width:100%;
		height:400px;
		border: $gray-color08 solid 1px;

		iframe {
			width: 100%;
			height: 100%;
		}
	}
}
@media screen and (max-width:840px) {
	section.map {
		margin: 0 auto 40px;

		#map{
			width: auto;
		}
	}
}

/*--------------------------------
section.sns
--------------------------------*/
section.sns {
	margin: 0 auto 60px;

	ul {
		width: 100%;
		text-align: center;

		li {
			width: 15%;
			margin: 0 10px;
			display: inline-block;
			font-size: 40px;

			&#facebook {
				background-color: #3b5998;
			}

			&#twitter {
				background-color: #55acee;
			}

			&#facebook:hover,
			&#twitter:hover{
				opacity: .6;
			}

			a {
				padding: 5px;
				display: block;
				box-sizing: border-box;
				font-size: 20px;
				color: $lightest-color;
					-webkit-transition: .4s;
					-moz-transition: .4s;
					-o-transition: .4s;
					-ms-transition: .4s;
				transition: .4s;
			}
		}
	}
}
@media screen and (max-width:840px) {
	section.sns {
		margin: 0 auto 20px;

		ul {
			li {
				width: 40%;
			}
		}
	}
}

/*--------------------------------
section.footer_nav
--------------------------------*/
section.footer_nav {
	margin: 0 auto 40px;

	ul {
		margin: 0 auto;
		text-align: center;

		li {
			display: inline-block;
			font-size: 14px;
			width: 10em;
			text-align: left;

			&:nth-last-child(n+2) {
				padding: 0 10px;
				margin: 0 5px 0 12px;
				border-right: 1px $darkest-color solid;
				text-align: right;
			}
		}
	}
}
@media screen and (max-width:840px) {
	section.footer_nav {
		margin: 0 auto 20px;
	}
}

/*--------------------------------
footer
--------------------------------*/
footer {
	width: 100%;

	.inner {
		p#copy {
			font-size: 16px;
		}
	}
}
@media screen and (max-width:840px) {
	footer {
		height: 100px;
		width: 100%;

		.inner {
			padding: 1em 0.5em;

			p#copy {
				font-size: 12px;
			}
		}
	}
	body#privacy footer,
	body#form footer{
		height: auto;
	}
}

/*--------------------------------
section.oubo_btn
--------------------------------*/
section.oubo_btn {
	position: fixed;
	top: 20%;
	right: 6px;
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
	text-align: center;
	z-index: 100;

	.btn {
		a {
			background-color: $accent-color;
			display: inline-block;
			width: 60px;
			min-height: 220px;
			box-sizing: border-box;
			margin: 0 auto;
			box-shadow: 6px 6px;
			position: relative;

			&:active {
				margin: 6px -6px -6px 6px;
				box-shadow: 0px 0px;
			}

			span {
				&.inner_txt {
					position: absolute;
					top: 35%;
					right: 50%;
					margin: -2em -0.5em 0 0;
					font-size: 24px;
					line-height: 24px;
					color: $lightest-color;
				}

				&.inner_img {
					position: absolute;
					display: block;
					width: 100%;
					height: 100%;
	
					img {
						position: absolute;
						bottom: 20px;
						left: 50%;
						margin-left: -12px;
					}
				}
			}
		}
	}
}
@media screen and (max-width:840px) {
	section.oubo_btn {
		position: fixed;
		top: auto;
		bottom: -100px;
		right: 0;
		left: 0;
		-webkit-writing-mode: horizontal-tb;
			-ms-writing-mode: tb;
				writing-mode: horizontal-tb;
		z-index: 99;

		.btn {
			a {
				background: $accent-color url(../img/ico_ashigaru.png) no-repeat 95% center;
				background-size: 12px 25px;
				width: 100%;
				min-height: auto;
				padding: 10px 0;

				&:active {
					margin: 0;
				}

				span {
					position: static;
					margin: 0;
					font-size: 18px;
					color: $lightest-color;
				}
			}
		}
	}
}

/*--------------------------------
section.privacy
--------------------------------*/
section {
	&.privacy {
		margin: 0 auto 100px;
	}

	&.privacy_box {
		margin: 0 auto 40px;

		h2.section_ttl {
			font-size: 24px;
			line-height: 50px;
			font-weight: bold;
			margin-bottom: 20px;
			text-align: left;

			span {
				padding: 0 34px;
				display: inline-block;
				background: url(../img/ico_ashigaru.png) no-repeat left center;
				min-height: 50px;
			}
		}

		p {
			font-size: 16px;
			line-height: 28px;
			margin-bottom: 10px;
		}

		ol {
			margin: 0 auto;

			li {
				font-size: 16px;
				line-height: 22px;
				margin-bottom: 8px;
			}
		}

		.personal_info {
			margin-bottom: 10px;

			p:nth-of-type(2n) {
				padding-left: 1em;
			}

			ul {
				margin: 20px 0;

				li {
					margin-bottom: 4px;
				}
			}
		}
	}

	h2.privacy_ttl {
		margin-bottom: 40px;
		font-size: 36px;
	}
}
@media screen and (max-width:840px) {
	section {
		.privacy {
			width: 94%;
			margin: 0 auto 40px;
		}

		h2 {
			&.privacy_ttl {
				font-size: 20px;
				margin-bottom: 20px;
			}

			&.section_ttl {
				font-size: 18px;
				line-height: 25px;

				span {
					padding: 0 20px;
					background-size: 12px 25px;
					min-height: auto;
				}
			}
		}

		p, ol li {
			font-size: 14px;
			line-height: 22px;
		}

		ul li, ol li{
			margin-bottom: 4px;
		}
	}
}

/*--------------------------------
scroll efect
--------------------------------*/
.sa {
  opacity: 0;
  transition: all .5s ease;
}

.sa.show {
  opacity: 1;
  transform: none;
}

.sa--lr {
  transform: translate(-100px, 0);
}

.sa--rl {
  transform: translate(100px, 0);
}

.sa--up {
  transform: translate(0, 100px);
}

.sa--down {
  transform: translate(0, -100px);
}

.sa--scaleUp {
  transform: scale(.5);
}

.sa--scaleDown {
  transform: scale(1.5);
}

.sa--rotateL {
  transform: rotate(180deg);
}

.sa--rotateR {
  transform: rotate(-180deg);
}

/* -------------------------------
 ページスクロール
------------------------------- */
#page-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 14px;
	line-height: 20px;

	span {
		display: block;
	}

	a {
		background-color: rgba(0,0,0,0.60);
		text-decoration: none;
		color: $lightest-color;
		width: 70px;
		padding: 10px 0;
		text-align: center;
		display: block;
		border-radius: 2px;

		&:hover {
			text-decoration: none;
			background: #999;
		}
	}
}
@media screen and (max-width:840px) {
	#page-top {
		position: fixed;
		bottom: 60px;
		right: 10px;
		font-size: 14px;
		line-height: 20px;

		span {
			display: none;
		}

		a {
			width: 40px;
			padding: 4px 0;
		}
	}
}

/* -------------------------------
 ローカルナビ（スライダー）
------------------------------- */
.recruit_nav {
	margin: 0 auto 60px;
}
ul#localNav {
	width: 1000px;
	margin: 0 auto 10px;
	display: flex;
	position: relative;

	li.bnr_nav {
		width: 280px;
		margin: 0 10px;

		a {
			display: block;
		}
	}

	/*左右の矢印の色を変える*/
	.slick-prev:before,
	.slick-next:before {
		color: $darkest-color;
	}

	/*左右の矢印の位置を変える*/
	.slick-next {
		right: -25px;
		z-index: 99;
	}
	.slick-prev {
		left: -25px;
		z-index: 100;
	}

	.slick-dots {
		bottom: -30px;
		width: 1000px;
		margin-left: -500px;
		left: 50%;

		li {
			margin: 0;
		}
	}

	/*スライド画像の横幅可変*/
	img {
		max-width: 100%;
		height: auto;
	}
}

/*スライド数のドットの色を変える*/
ul#localNav .slick-dots li.slick-active button:before,
ul#localNav .slick-dots li button:before {
    color: $darkest-color;
}

@media screen and (max-width:840px) {
	.recruit_nav {
		margin: 0 auto 60px;
	}
	ul#localNav {
		width: 100%;

		.slick-dots {
			width: 100%;
			margin-left: auto;
			left: auto;
		}
	}
}

/* -------------------------------
 スクロールアイコン
------------------------------- */
#scroll_down {
	display: none;
}

@media screen and (min-width:330px) and (max-width:768px) {
	#scroll_down {
		display: block;
		position: fixed;
		bottom: 60px;
		left: 0;
		font-size: 14px;
		width: 100%;
		text-align: center;
	
		img {
			width: 24px;
			height: auto;
		}
	
		p {
			font-size: 14px;
				line-height: 12px;
				color: $darkest-color;
		}
	}
	
	/* 点滅 */
	#scroll_down {
		-webkit-animation:blink .6s ease-in-out infinite alternate;
		-moz-animation:blink .6s ease-in-out infinite alternate;
		animation:blink .6s ease-in-out infinite alternate;
	}
	@-webkit-keyframes blink{
		0% {opacity:0;}
		100% {opacity:1;}
	}
	@-moz-keyframes blink{
		0% {opacity:0;}
		100% {opacity:1;}
	}
	@keyframes blink{
		0% {opacity:0;}
		100% {opacity:1;}
	}
}

/*--------------------------------
 エントリーフォーム
--------------------------------*/
input {
	-webkit-appearance:none;
	-moz-appearance:none;
	-ms-appearance:none;
	appearance:none;
	border-radius: 0;
}
section.form {
	margin: 0 auto 80px;

	dl {
		width: 100%;
		margin: 0 auto 80px;
		padding: 20px;
		background-color: rgba(255,255,255,0.4);
		box-sizing: border-box;
		border: #dddddd solid 1px;

		dt {
			width: 340px;
			float: left;
			clear: left;
			padding: 20px 20px;
			font-size: 18px;
			font-weight: bold;

			span.required:after {
				content: "必須";
				font-size: 12px;
				color: $lightest-color;
				padding: 4px 6px;
				background-color: $accent-color;
				margin: -4px 0 0 10px;
				position: relative;
				top: -2px;
			}
		}

		dd {
			width: 100%;
			min-height:66px;
			padding: 20px 20px 20px 380px;
			border-bottom: #ddd dashed 1px;
			box-sizing: border-box;
			font-size: 18px;

			&:last-child {
				border-bottom: none;
			}
		}
	}

	ul {
		li {
			margin-bottom: 10px;
		}
	}

	p {
		font-size: 16px;
		line-height: 22px;
		margin-bottom: 16px;
	}

	label {
		cursor: pointer;

		&.form_file:hover {
			background: linear-gradient(0deg, #c1bfbf, rgba(255,0,0,0));
		}
	}
}
@media screen and (max-width:840px) {
	section.form {
		dl {
			padding: 20px 10px 0px;
			margin-bottom: 20px;

			dt {
				width: 100%;
				padding: 0px 10px 10px;
				float: none;
			}
			dd {
				padding: 0px 10px 20px;
				margin-bottom: 20px;
				min-height: auto;
			}		
		}
	}
}

/* テキスト */
.form_text {
	font-size: 16px;
	width: 100%;
	padding: 6px;
	box-sizing: border-box;
	border: 1px solid $gray-color03;
}
/* セレクトボックス */
.form_select {
	overflow: hidden;
	width: 100%;
	text-align: center;
	position: relative;
	border: 1px solid $gray-color03;
	border-radius: 2px;
	background: $lightest-color;

	&.age {
		width: 30%;
	}

	&.job {
		width: 40%;
	}

	select {
		width: 100%;
		padding-right: 1em;
		cursor: pointer;
		text-indent: 0.01px;
		text-overflow: ellipsis;
		border: none;
		outline: none;
		background: transparent;
		background-image: none;
		box-shadow: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		&::-ms-expand {
			display: none;
		}
	}

	&::before {
		position: absolute;
		top: 50%;
		right: 0.9em;
		width: 0;
		height: 0;
		padding: 0;
		content: '';
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-top: 6px solid $gray-color07;
		pointer-events: none;
		transform: translateY(-50%);
	}

	&:after {
		position: absolute;
		top: 0;
		right: 2.5em;
		bottom: 0;
		width: 1px;
		content: '';
		border-left: 1px solid $gray-color03;
	}

	select {
		padding: 8px 38px 8px 8px;
		color: $gray-color07;
		font-size: 14px;
		color: $darkest-color;
		display: block;
	}
}
@media screen and (max-width:840px) {
	.form_select{
		&.age {
			width: 30%;
		}
		&.job {
			width: 100%;
		}
	}
}

/* ラジオボタン&チェックボックス */
@font-face{font-family:"IonIcons";src:url("//code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1");src:url("//code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.eot?v=2.0.1#iefix") format("embedded-opentype"),url("//code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.ttf?v=2.0.1") format("truetype"),url("//code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.woff?v=2.0.1") format("woff"),url("//code.ionicframework.com/ionicons/2.0.1/fonts/ionicons.svg?v=2.0.1#Ionicons") format("svg");font-weight:normal;font-style:normal}

/* チェックボックス */
.form_checkbox{
  cursor: pointer;
  color: #828c9a;
  padding-right: 15px;
  font-weight: normal;

  input[type="checkbox"]{
	display: none;
  }

  .lever:before{
	content:"\f372";
	font-family: "IonIcons";
	margin-right: 7px;
	color: $gray-color08;
	font-size: 18px;
	position: relative;
	top: 2px;
  }

  input[type="checkbox"]:checked {
	+ .lever{
		color: #4788bf;

		&:before{
			content:"\f373";
			font-family: "IonIcons";
			color: #4788bf;
		  }
	  }
  }
}

/* ラジオボタン */
.form_radio{
  cursor: pointer;
  padding-right: 15px;
  font-weight: normal;

  input[type="radio"]{
	display: none;
  }

  span.vertical-item{
	display: block;
  }

  span.mwform-radio-field-text:before{
	content:"\f3a6";
	font-family: "IonIcons";
	margin-right: 7px;
	color: $gray-color08;
	font-size: 18px;
	position: relative;
	top: 2px;
  }

  label:first-child {
	margin-right: 12px;
  }

  input[type="radio"]:checked {
	+ span{
		color: $accent-color;

		&:before{
			content:"\f3a7";
			font-family: "IonIcons";
			color: $accent-color;
		  }
	  }
  }
}

/* ファイルボタン（使用停止中） */
// .form_file {
//   background-color: $lightest-color;
//   padding: 8px;
// 	border: 1px solid $gray-color04;
// 	font-size: 14px;
// 	cursor: pointer;
// }
#form_file {
	display: none;
}

/* テキストボックス */
.form_textbox {
	border: 1px solid $gray-color04;
	background-color: $lightest-color;
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
}

/* ボタン */
.btn_area {
	width: 100%;
	margin-bottom: 30px;
	text-align: center;
	display: flex;
	justify-content: center;

	li {
		width: 350px;
		margin: 0 20px;

		span {
			width: 350px;
			display: inline-block;
			text-align: center;
			font-size: 30px;
			color: $lightest-color;
			padding: 20px 0;
			position: relative;

			&:active {
				box-shadow: 0px 0px!important;
				margin: 6px -6px -6px 6px;
			}
		}

		&.reset span, &.back span {
			background-color: $gray-color05;
			box-shadow: 6px 6px rgba(124,124,124,1.0);
		}

		&.submit span {
			background-color: $accent-color;
			box-shadow: 6px 6px rgba(73,73,73,1.0);

			button {
				display: none;
			}
		}

		input {
			width: 100%;
			height: 100%;
			position: absolute;
			top:0;
			right: 0;
			bottom: 0;
			left: 0;
			font-size: 30px;
			border: none;
			cursor: pointer;
			opacity: 0.0;
		}
	}
}
@media screen and (max-width:840px) {
	.btn_area {
		li {
			width: 48%;
			margin: 0 10px;
		
			span {
				width: 100%;
				font-size: 14px;
				padding: 10px 0;
		
				&:active {
					box-shadow: 0px 0px!important;
					margin: 0;
				}
			}

			&.reset span, &.submit span {
				box-shadow: 3px 3px;
			}
		}
	}
}

/* MW ボタン */
.mw_wp_form {
	.btn_area {
		button {
			width: 350px;
			margin: 0 20px;
			display: inline-block;
			text-align: center;
			font-size: 30px;
			color: $lightest-color;
			padding: 20px 0;
			position: relative;
			border: none;
			background-color: $accent-color;
			box-shadow: 6px 6px rgba(73,73,73,1.0);
			cursor: pointer;

			&:active {
				box-shadow: 0px 0px!important;
				margin: 6px 10px -6px 30px;
			}

			&[name="submitBack"] {
				background-color: $gray-color05;
				box-shadow: 6px 6px rgba(124,124,124,1.0);
			}
		}

		li {
			input {
				width: 100%;
				height: 100%;
				position: absolute;
				top:0;
				right: 0;
				bottom: 0;
				left: 0;
				font-size: 30px;
				border: none;
				cursor: pointer;
				opacity: 0.0;
			}
		}

		li.submit {
			span {
				button {
					display: none;
				}
			}
		}
	}
}

/* .mw_wp_form .btn_area button {
	font-size: 18px;
	padding: 10px 20px;
	margin: 0 10px;
	border: #666 solid 1px;
	border-radius: 2px;
	box-sizing: border-box;
	cursor: pointer;
	background-color: $gray-color02;
  }
.mw_wp_form .btn_area button:hover {
	color: $lightest-color;
	background-color: #666;
  } */

/* エラーメッセージ */
.error_message {
	width: 20em;
	margin: 0 auto 80px;
}
.mw_wp_form {
	.error {
		text-align: left;
	}

	.form_select {
		position: relative;
		text-align: left;
		padding: 0 10px;
	}

	.form_select {
		.error {
			position: absolute;
			bottom: 0;
		}
	}
}